import styled, { css } from "styled-components";

const largeLayout = css`
  display: grid;

  @media (min-width: 575px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Card = styled.div`
  width: 100%;
  border-radius: 5px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(168, 168, 168, 0.5);
  padding: 26px 20px 20px;
  color: initial;

  ${(props) => props.isLarge && largeLayout};
`;
