import React from "react";
import { Label } from "../Label";
import { Select } from "../Select";

interface SelectInputInferface {
  label?: string;
  options: any;
  isInverted?: boolean;
  onChange?: Function;
  value?: any;
}

export const SelectInput: React.FC<SelectInputInferface> = ({
  label = "label",
  options,
  isInverted,
  onChange,
  value,
}) => (
  <Label label={label} isInverted={isInverted}>
    <Select options={options} handleChange={onChange} value={value} />
  </Label>
);
