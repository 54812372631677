import React from "react";
import { Card as StyledCard } from "./styles";

interface CardType {
  className?: string;
  isLarge?: boolean;
}

export const Card: React.FC<CardType> = ({ children, isLarge, className }) => (
  <StyledCard className={className} isLarge={isLarge}>
    {children}
  </StyledCard>
);
