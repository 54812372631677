import React from "react"
import { Label as StyledLabel } from "./styles"

interface LabelType {
  label: string
  isInverted?: boolean
}

export const Label: React.FC<LabelType> = ({ children, label, isInverted }) => (
  <StyledLabel isInverted={isInverted}>
    {label}
    {children}
  </StyledLabel>
)
