import { capitalize, formatPrice } from "../../utils";

export const getFilterQueryString = (
  manufacturer,
  model,
  minPrice,
  maxPrice
) => {
  let arr = [];

  if (manufacturer) {
    arr = [...arr, `manufacturer=${manufacturer.value}`];
  }

  if (model) {
    arr = [...arr, `model=${model.value.split(" ").join("+")}`];
  }

  if (minPrice) {
    arr = [...arr, `min-price=${minPrice.value}`];
  }

  if (maxPrice) {
    arr = [...arr, `max-price=${maxPrice.value}`];
  }

  return arr.length ? `?${arr.join("&")}` : null;
};

export const setFilterDetault = (values, filter) => {
  if (!values || !values[filter]) return undefined;

  const value = values[filter];

  return {
    label: isNaN(value) ? capitalize(value) : formatPrice(value),
    value,
  };
};
