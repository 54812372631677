import styled, { css } from "styled-components";

const boxGrid = css`
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Form = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media (min-width: 850px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }

  ${({ isBox }) => isBox && boxGrid}
`;

const boxCTA = css`
  grid-column: 1 / -1;

  @media (min-width: 850px) {
    grid-column: 1 / -1;
  }

  @media (min-width: 1024px) {
    grid-column: 1 / -1;

    button {
      font-size: 18px;
      height: 50px;
    }
  }
`;

export const CTA = styled.div`
  grid-column: 1 / -1;
  margin-top: 20px;

  @media (min-width: 850px) {
    grid-column: 2 / -2;
  }

  @media (min-width: 1024px) {
    grid-column: 5 / -1;
    margin-top: 20px;

    button {
      font-size: 16px;
      height: 38px;
    }
  }

  ${({ isBox }) => isBox && boxCTA}
`;
