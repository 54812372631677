import styled, { css } from "styled-components";
import { Link as _Link } from "../Link";
import { lightGrey, black, red } from "../../styles/colors";

export const largeImage = css`
  @media (min-width: 575px) {
    width: calc(100% + 20px);
    margin: -26px 0 -20px -20px;
    border-radius: 4px 0 0 4px;
    padding-bottom: 0;
    height: calc(100% + 46px);
  }
`;

export const Image = styled.div`
  position: relative;
  width: calc(100% + 40px);
  padding-bottom: 83%;
  background: ${lightGrey};
  margin: -26px -20px 20px;
  border-radius: 4px 4px 0 0;

  ${({ isLarge }) => isLarge && largeImage}
`;

const largeGallery = css`
  @media (min-width: 575px) {
    position: relative;
    border-radius: 4px 0 0 4px;
  }
`;

export const Gallery = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;

  ${({ isLarge }) => isLarge && largeGallery}
`;

const largeContent = css`
  @media (min-width: 575px) {
    padding-left: 20px;
    min-height: 250px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;

  ${({ isLarge }) => isLarge && largeContent}
`;

export const Price = styled.span`
  position: relative;
  order: 1;
  margin-top: 4px;
  margin-bottom: 10px;
  padding-top: 18px;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.5px;

  &::before {
    content: "Our price";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    font-weight: 300;
  }
`;
export const Heading = styled.h1`
  order: 2;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 2px;
  line-height: 1.2;
`;

const largeDescription = css`
  @media (min-width: 575px) {
    margin-bottom: 20px;
  }
`;

export const Description = styled.p`
  order: 3;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.75px;
  line-height: 1.3;

  ${({ isLarge }) => isLarge && largeDescription}
`;

const largeTextLink = css`
  @media (min-width: 575px) {
    margin-top: 0;
  }
`;

export const TextLink = styled(_Link)`
  order: 5;
  margin-top: auto;

  &:visited,
  &:active {
    color: ${red};
  }

  ${({ isLarge }) => isLarge && largeTextLink}
`;

const largeDetailsList = css`
  @media (min-width: 850px) {
    display: block;
  }

  @media (min-width: 1024px) {
    margin-bottom 20px;
  }
`;

export const DetailsList = styled.ul`
  display: none;
  order: 4;
  list-style: disc;

  ${({ isLarge }) => isLarge && largeDetailsList}
`;

export const DetailsItem = styled.li`
  margin-bottom: 12px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.2;
  color: ${black};
  margin-left: 20px;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;
