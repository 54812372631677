import styled, { css } from "styled-components";
import { darkGrey, white } from "../../styles/colors";

const inverted = css`
  color: ${white};
`;

export const Label = styled.label`
  color: ${darkGrey};
  font-size: 14px;
  font-weight: 300;

  > * {
    margin-top: 6px;
    font-size: initial;
    color: ${darkGrey};
  }

  ${(props) => props.isInverted && inverted}
`;
