import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { FilterPriceOptions } from "../../constants";
import { capitalize, formatPrice } from "../../utils";
import { SelectInput } from "../SelectInput";
import { Button } from "../Button";
import { Form, CTA } from "./styles";
import { getFilterQueryString, setFilterDetault } from "./utils";

const ALL_MANUFACTURERS_MODELS_QUERY = graphql`
  query AllManufacturersModels {
    allWordpressWpVehicle(
      sort: { fields: [acf___manufacturer, acf___model], order: [ASC, ASC] }
      filter: { acf: { sold: { ne: true } } }
    ) {
      edges {
        node {
          acf {
            manufacturer
            model
          }
        }
      }
    }
  }
`;

const filterManufacturers = ({ acf }, model) =>
  model && model.value !== "any" && acf.model.toLowerCase() !== model.value
    ? false
    : true;

const filterModels = ({ acf }, manufacturer) =>
  manufacturer &&
  manufacturer.value !== "any" &&
  acf.manufacturer.toLowerCase() !== manufacturer.value
    ? false
    : true;

interface SearchFormType {
  filters?: any;
  isInverted?: boolean;
  isBox?: boolean;
}

export const SearchForm: React.FC<SearchFormType> = ({
  filters,
  isInverted,
  isBox,
}) => {
  const [manufacturer, setManufacturer] = useState(
    setFilterDetault(filters, "manufacturer")
  );
  const [model, setModel] = useState(setFilterDetault(filters, "model"));
  const [minPrice, setMinPrice] = useState(
    setFilterDetault(filters, "minPrice")
  );
  const [maxPrice, setMaxPrice] = useState(
    setFilterDetault(filters, "maxPrice")
  );

  const {
    allWordpressWpVehicle: { edges },
  } = useStaticQuery(ALL_MANUFACTURERS_MODELS_QUERY);

  const manufacturerList = [
    ...new Set([
      "Any",
      ...edges
        .filter(({ node }) => filterManufacturers(node, model))
        .map(({ node }) => node.acf.manufacturer),
    ]),
  ];
  const modelList = [
    ...new Set([
      "Any",
      ...edges
        .filter(({ node }) => filterModels(node, manufacturer))
        .map(({ node }) => node.acf.model),
    ]),
  ];
  const minPriceList = ["Any", ...FilterPriceOptions];
  const maxPriceList = ["Any", ...FilterPriceOptions];
  const queryString = getFilterQueryString(
    manufacturer,
    model,
    minPrice,
    maxPrice
  );

  return (
    <Form isBox={isBox}>
      <SelectInput
        label="Manafacturer"
        options={manufacturerList.map((value) => ({
          value: value.toLowerCase(),
          label: value,
        }))}
        onChange={(selected) => setManufacturer(selected)}
        value={manufacturer}
        isInverted={isInverted}
      />
      <SelectInput
        label="Model"
        options={modelList.map((value) => ({
          value: value.toLowerCase(),
          label: value,
        }))}
        onChange={(selected) => setModel(selected)}
        value={model}
        isInverted={isInverted}
      />
      <SelectInput
        label="Min price"
        options={minPriceList.map((value) => ({
          value: value === "Any" ? value.toLowerCase() : value,
          label: isNaN(Number(value)) ? value : `From ${formatPrice(value)}`,
        }))}
        onChange={(selected) => setMinPrice(selected)}
        value={minPrice}
        isInverted={isInverted}
      />
      <SelectInput
        label="Max price"
        options={maxPriceList.map((value) => ({
          value: value === "Any" ? value.toLowerCase() : value,
          label: isNaN(Number(value)) ? value : `To ${formatPrice(value)}`,
        }))}
        onChange={(selected) => setMaxPrice(selected)}
        value={maxPrice}
        isInverted={isInverted}
      />
      <CTA isBox={isBox}>
        <Link to={`/our-cars${queryString ? `/${queryString}` : ""}`}>
          <Button palette={isInverted ? "inverted" : "primary"} fullWidth>
            Search now
          </Button>
        </Link>
      </CTA>
    </Form>
  );
};
