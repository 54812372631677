import React from "react";
import { Card } from "../Card";
import { Link } from "gatsby";
import {
  Content,
  Image,
  Gallery,
  Price,
  Heading,
  Description,
  DetailsList,
  DetailsItem,
  TextLink,
} from "./styles";
import placeholder from "../../assets/placeholder.jpg";

interface VehicleCardType {
  image: string;
  price: string;
  title: string;
  description: string;
  href: string;
  isLarge?: boolean;
  summary?: Array<string>;
}
export const VehicleCard: React.FC<VehicleCardType> = ({
  image,
  price,
  title,
  description,
  href,
  isLarge,
  summary,
}) => (
  <Card isLarge={isLarge}>
    <Link to={href}>
      <Image isLarge={isLarge}>
        <Gallery isLarge={isLarge} src={image ? image : placeholder} />
      </Image>
    </Link>
    <Content isLarge={isLarge}>
      <Heading isLarge={isLarge}>{title}</Heading>
      <Description isLarge={isLarge}>{description}</Description>
      <Price isLarge={isLarge}>{price}</Price>
      {summary && summary.length > 0 && (
        <DetailsList isLarge={isLarge}>
          {summary.map((item) => item && <DetailsItem>{item}</DetailsItem>)}
        </DetailsList>
      )}
      <TextLink to={href} isLarge={isLarge}>
        View vehicle details
      </TextLink>
    </Content>
  </Card>
);
